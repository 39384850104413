














































import {Component, Vue} from 'vue-property-decorator'
import ChuFangApi from '@/api/chufang'

@Component({})
export default class BasicInformation extends Vue {

  private api = new ChuFangApi()

  private dataForm = {
    sex: undefined,
    age: undefined,
    weight: undefined,
    height: undefined,
    waistline: undefined,
    hipline: undefined
  }

  private get aid() {
    return this.$route.params.aid
  }

  private async activated() {
    await this.getLast()
  }

  private deactivated() {
  }

  private async getLast() {
    const result = await this.api.getLastAssessment(false)
    if (result) {
      this.dataForm = Object.assign({}, result)
    }
  }

  private async handleFinish() {
    if (
      !this.dataForm.sex ||
      !this.dataForm.age ||
      !this.dataForm.weight ||
      !this.dataForm.height ||
      !this.dataForm.waistline ||
      !this.dataForm.hipline
    ) {
      this.$notify({type: 'warning', message: '不能为空'})
      return false
    }

    await this.api.saveBasicInfo(this.aid, this.dataForm)

    await this.$router.replace(`/personal-tailor/apparatus-measure/${this.aid}`)
  }
}
